<template>
  <div class="heaberColor">
    <!-- <div class="heaber">
      <el-page-header @back="goBack" content="产品溯源"></el-page-header>
    </div> -->
    <div style="background-color: deepskyblue;height:50vh">
      <div>
        <img class="headerImg" src="@/assets/H5/29.png" alt="" />
      </div>

      <div class="main">
        <div style="margin-left: 2vw;margin-right: 2vw;line-height: 4vh;border-bottom: 1px solid #dcdfe6;">
          <!-- <div style="font-family: Regular;font-size: 14px;letter-spacing: 1px;color: #000;">溯源码：<span style="font-family: Regular;font-size: 14px;color: #000;">SYBM8985521852111#</span></div> -->
        </div>
        <div style="margin-left:2vw;margin-top: 1vh;">
          <div style="float: left;width:25% ;">
            <img style="width:100px ;height: 110px;" src="@/assets/H5/956.png" alt="" />
          </div>
          <div style="float: left;width:70%;margin-left:4vw;">
            <div class="main_right">
              作物名称：<span>{{ plantingBatchBean.plantName }}</span>
            </div>
            <div class="main_right">
              种植批次：<span>{{ plantingBatchBean.batchNumber }}</span>
            </div>
            <!-- <span style="font-family: Regular;font-size: 12px;color: #000;">GT/T12863</span> -->
            <div class="main_right" v-if="activeSate">
              <span>采收批次：</span>
              <span style="word-break:break-all">{{ harvestBatch }}</span>
            </div>
            <!-- <div class="main_right">加工批次：<span>SFBH002</span></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 标签页 -->
    <el-tabs style="margin-top: 6vh;" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <div style="width:100vw ;height: 80vh;font-family: Regular;font-size: 14px;color: #000;">
          <div style="margin-left:4vw;">
            <div class="basic">
              <span>产品名称</span>
              <span style="float: right;margin-right: 4vw;">{{ plantingBatchBean.plantName }}</span>
            </div>
            <div class="basic">
              <span>种植地块</span>
              <span style="float: right;margin-right: 4vw;">{{ plantingBatchBean.plotName }}</span>
            </div>
            <div class="basic">
              <span>种植批次</span>
              <span style="float: right;margin-right: 4vw;">{{ plantingBatchBean.batchNumber }}</span>
            </div>
            <div class="basic">
              <span>种植类别</span>
              <span style="float: right;margin-right: 4vw;">{{ plantingBatchBean.plantType }}</span>
            </div>
            <div class="basic">
              <span>地块地址</span>
              <span style="float: right;margin-right: 4vw;">{{ landPlotBean.address }}</span>
            </div>
            <div class="basic">
              <span>资质证书</span>
              <span style="display: block;margin-left:4vw;">
                <img style="width:100px ;height: 110px;" :src="enterpriseBean.businessLicenseImg" alt="" />
                <!-- <img style="width:100px ;height: 110px;" src="../../assets/H5/956.png" alt="" />
                <img style="width:100px ;height: 110px;" src="../../assets/H5/956.png" alt="" /> -->
              </span>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="视频信息" name="second">
        <div v-for="item in cameraBeanList" :key="item.id">
          <div style="width:95% ;height:20vh; text-align: center;margin:0 auto">
            <video
              style="width: 100%; height: 100%; object-fit: fill;"
              :id="'screenVideo' + item.id"
              class="video-js vjs-default-skin vjs-big-play-centered videoStyle"
              :src="item.hls"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
            ></video>
          </div>
          <div style="width:100vw ;font-family: Regular;font-size: 14px;">
            <div style="margin-left:4vw;margin-right:4vw;margin-bottom:1vh;border-radius: 8px;">
              <div class="process" style="color: blue;">
                <span>{{ item.name }}</span>
                <span style="float: right;margin-right: 2vw;">正在播放</span>
              </div>
              <!-- <div class="process">
                <span style="display: block;float: right;margin-right: 2vw;">2020-04-29</span>
              </div> -->
              <!-- <div class="process">
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
                <span style="display: block;">2020-04-29 8:00-9:00视频回放</span>
              </div> -->
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="流程信息" name="third">
        <div style="width:100vw ;height: 100%;font-family: Regular;font-size: 14px;">
          <div v-for="item in technological" :key="item.id" style="margin-top:2vh">
            <div style="background-color: green;margin-left:4vw;margin-right:4vw;margin-bottom:1vh;border-radius: 8px;">
              <div class="process" style="color: #fff;">
                <span>{{ item.name }}</span>
                <span style="float: right;margin-right: 4vw;">开始时间：{{ item.createTime }}</span>
              </div>
            </div>
            <div style="margin-left:4vw;margin-left:4vw;margin-right:4vw;background-color: darkseagreen;border-radius: 8px;">
              <div class="flow" style="color: blue;">
                <span>种植批次：</span>
                <span>{{ batchInfo.batchNumber || '暂无' }}</span>
              </div>
              <div class="flow">
                <span>作物名称：</span>
                <span style="float: right;margin-right: 3vw;">{{ batchInfo.plantName || '暂无' }}</span>
                <!-- <span style="float: right;margin-right: 4vw;">ZZBH001</span> -->
              </div>
              <div class="flow">
                <span>作物类别</span>
                <span style="float: right;margin-right: 3vw;">{{ batchInfo.plantType || '暂无' }}</span>
              </div>
              <div class="flow">
                <span>地块名称</span>
                <span style="float: right;margin-right: 3vw;">{{ batchInfo.plotName || '暂无' }}</span>
              </div>
              <div class="flow">
                <span>面积信息</span>
                <span style="float: right;margin-right: 3vw;color: blue;">{{ batchInfo.plantingArea || '暂无' }}</span>
              </div>
              <div class="flow">
                <span>种植时间</span>
                <!-- <span style="float: right;margin-right: 3vw;color: blue;">{{ item.createTime || '暂无' }}</span> -->
                <span style="float: right;margin-right: 3vw;color: blue;">{{ batchInfo.plantingTime || '暂无' }}</span>
              </div>
              <div class="flow">
                <span>天数</span>
                <span style="float: right;margin-right: 2vw;color: blue;">{{ item.day || '暂无' }}天</span>
              </div>
              <div class="processImg">
                <span>当前阶段图片</span>
                <span style="float: right;margin-right: 2vw;">
                  <img style="width:50px ;height: 50px;margin-right: 2vw;" :src="item.url" alt="" />
                </span>
              </div>
              <!-- 农事活动 -->
              <div v-for="items in item.list" :key="items.farmingTaskNewBean.id">
                <div class="flow">
                  <span style="color: blue;">农事活动</span>
                </div>
                <div style="padding-bottom: 2vh;">
                  <div style="margin-left:4vw;margin-right:4vw;border: 1px solid green;border-radius: 8px;">
                    <div class="flow">
                      <span>任务名称</span>
                      <span style="float: right;">{{ items.farmingTaskNewBean.name }}</span>
                    </div>
                    <div class="flow">
                      <span>任务执行人</span>
                      <span style="float: right;">{{ items.farmingTaskNewBean.executor }}</span>
                    </div>
                    <div class="flow">
                      <span>任务下发人</span>
                      <span style="float: right;">{{ items.farmingTaskNewBean.issuer }}</span>
                    </div>
                    <div class="flow">
                      <span>任务下发时间</span>
                      <span style="float: right;">{{ items.farmingTaskNewBean.completionTime }}</span>
                    </div>
                    <div class="flow">
                      <span>投入品</span>
                      <span style="float: right;">{{ inputName(items.inputUseRecordBeans) }}</span>
                    </div>
                    <div class="flow">
                      <span>冬季抗倒伏小麦种子使用量</span>
                      <span style="float: right;">{{ inputQuantity(items.inputUseRecordBeans) }}</span>
                    </div>
                    <div class="flow">
                      <span>任务详情</span>
                      <span style="display: block;">
                        <textarea disabled rows="3" cols="42" v-model="items.farmingTaskNewBean.taskDetails"></textarea>
                      </span>
                    </div>
                    <div class="flow">
                      <span>回执</span>
                      <span style="display: block;">
                        <textarea disabled rows="3" cols="42" v-model="items.farmingTaskNewBean.receipt"></textarea>
                      </span>
                    </div>
                    <div class="processImg">
                      <span>回执图片</span>
                      <span style="float: right;margin-right: 2vw;" v-if="items.farmingTaskNewBean.receiptImg">
                        <img v-for="(imgs, index) in items.farmingTaskNewBean.receiptImg" :key="index" style="width:50px ;height: 50px;margin-right: 2vw;" :src="imgs.imgUrls" alt="" />
                        <!-- <img style="width:50px ;height: 50px;" src="../../assets/H5/956.png" alt="" /> -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div style="margin-top:2vh;margin-left:4vw;margin-left:4vw;margin-right:4vw;background-color: darkseagreen;border-radius: 8px;">
            <div class="flow" style="color: blue;">
              <span>农事活动</span>
            </div>
            <div class="flow">
              <span>地块名称</span>
              <span style="float: right;margin-right: 3vw;">种植基地1号地</span>
            </div>
            <div class="flow">
              <span>面积信息</span>
              <span style="float: right;margin-right: 3vw;">2300㎡</span>
            </div>
            <div class="flow">
              <span>作物名称</span>
              <span style="float: right;margin-right: 3vw;color: blue;">张三三</span>
            </div>
            <div class="flow">
              <span>投入品</span>
              <span style="float: right;margin-right: 3vw;color: blue;">冬季抗倒伏小麦种子</span>
            </div>
            <div class="flow">
              <span>投入品</span>
              <span style="float: right;margin-right: 2vw;color: blue;">10人</span>
            </div>
            <div class="flow">
              <span style="color: blue;">农事活动</span>
            </div>
            <div style="padding-bottom: 2vh;">
              <div style="margin-left:4vw;margin-right:4vw;border: 1px solid green;border-radius: 8px;">
                <div class="flow">
                  <span>任务名称</span>
                  <span style="float: right;">李四</span>
                </div>
                <div class="flow">
                  <span>任务执行人</span>
                  <span style="float: right;">种植基地1号地</span>
                </div>
                <div class="flow">
                  <span>任务下发人</span>
                  <span style="float: right;">1150㎡</span>
                </div>
                <div class="flow">
                  <span>任务下发时间</span>
                  <span style="float: right;">30亩</span>
                </div>
                <div class="flow">
                  <span>氮肥施用量</span>
                  <span style="float: right;">30亩</span>
                </div>
                <div class="flow">
                  <span>任务详情</span>
                  <span style="display: block;">
                    <textarea rows="3" cols="42">种植任务完成，种植时天气状况良好，土壤湿润</textarea>
                  </span>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div style="background-color: deepskyblue;margin-top:2vh;margin-left:4vw;margin-right:4vw;margin-bottom:1vh;border-radius: 8px;">
            <div class="process" style="color: #fff;">
              <span>生长阶段</span>
              <span style="float: right;margin-right: 4vw;">开始时间</span>
            </div>
          </div> -->
          <!-- <div style="margin-left:4vw;margin-left:4vw;margin-right:4vw;background-color: lightblue;border-radius: 8px;">
            <div class="flow" style="color: blue;">
              <span>种植批次：</span>
              <span>ZZBH001</span>
            </div>
            <div class="flow">
              <span>种植地块</span>
              <span style="float: right;margin-right: 3vw;">种植基地1号地</span>
            </div>
            <div class="flow">
              <span>种植面积</span>
              <span style="float: right;margin-right: 3vw;">2300㎡</span>
            </div>
            <div class="flow">
              <span>负责人</span>
              <span style="float: right;margin-right: 3vw;color: blue;">张三三</span>
            </div>
            <div class="flow">
              <span>投入品</span>
              <span style="float: right;margin-right: 3vw;color: blue;">冬季抗倒伏小麦种子</span>
            </div>
            <div class="flow">
              <span>投入人员</span>
              <span style="float: right;margin-right: 2vw;color: blue;">10人</span>
            </div>
            <div class="flow">
              <span style="color: blue;">农事活动</span>
            </div>
            <div style="padding-bottom: 2vh;">
              <div style="margin-left:4vw;margin-right:4vw;border: 1px solid deepskyblue;border-radius: 8px;">
                <div class="flow">
                  <span>执行人</span>
                  <span style="float: right;">李四</span>
                </div>
                <div class="flow">
                  <span>种植地块</span>
                  <span style="float: right;">种植基地1号地</span>
                </div>
                <div class="flow">
                  <span>种植面积</span>
                  <span style="float: right;">1150㎡</span>
                </div>
                <div class="flow">
                  <span>播种量</span>
                  <span style="float: right;">30亩</span>
                </div>
                <div class="processImg">
                  <span>农事现场实拍</span>
                  <span style="float: right;margin-right: 2vw;">
                    <img style="width:50px ;height: 50px;margin-right: 2vw;" src="../../assets/H5/956.png" alt="" />
                    <img style="width:50px ;height: 50px;" src="../../assets/H5/956.png" alt="" />
                  </span>
                </div>
                <div class="flow">
                  <span>农事完结反馈</span>
                  <span style="display: block;">
                    <textarea rows="3" cols="42">种植任务完成，种植时天气状况良好，土壤湿润</textarea>
                  </span>
                </div>
              </div>
            </div>
          </div> -->
          <div style="height: 10vh;"></div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="检测信息" name="fourth">
        <div style="width:100vw ;height: 100%;font-family: Regular;font-size: 14px;color: #000;">
          <div style="margin-left:4vw;">
            <div class="basic">
              <span>生产批次</span>
              <span style="float: right;margin-right: 4vw;">SCPC-0001</span>
            </div>
            <div class="basic">
              <span>样品名称</span>
              <span style="float: right;margin-right: 4vw;">云雾绿茶</span>
            </div>
            <div class="basic">
              <span>甲胺磷</span>
              <span style="float: right;margin-right: 4vw;">0.1mg/kg</span>
            </div>
            <div class="basic">
              <span>对硫磷</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>水胺硫磷</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>马拉硫磷</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>氧化乐果</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>乙酰甲胺磷</span>
              <span style="float: right;margin-right: 4vw;">omg/kg</span>
            </div>
            <div class="basic">
              <span>敌敌畏</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>敌百虫</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>乐果</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>久效磷</span>
              <span style="float: right;margin-right: 4vw;">0.1mg/kg</span>
            </div>
            <div class="basic">
              <span>甲萘威</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>好年冬</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>呋喃丹</span>
              <span style="float: right;margin-right: 4vw;">0mg/kg</span>
            </div>
            <div class="basic">
              <span>检测项目</span>
              <span style="float: right;margin-right: 4vw;">农药残留</span>
            </div>
            <div class="basic">
              <span>检测日期</span>
              <span style="float: right;margin-right: 4vw;">2020-03-21</span>
            </div>
            <div class="basic">
              <span>检测机构</span>
              <span style="float: right;margin-right: 4vw;">中国农药监测机构</span>
              <span style="display: block;margin-left:4vw;height: 30vh;">
                <img style="width:100px ;height: 110px;" src="../../assets/H5/956.png" alt="" />
                <img style="width:100px ;height: 110px;" src="../../assets/H5/956.png" alt="" />
                <img style="width:100px ;height: 110px;" src="../../assets/H5/956.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </el-tab-pane> -->
      <el-tab-pane label="环境信息" name="fifth">
        <div style="width:100vw ;" v-for="(item, i) in deviceList" :key="i">
          <div v-if="item._ids.length != 0">
            <div v-for="(items, p) in item._ids" :key="items._ids">
              <div style="font-family: Regular;font-size: 14px;text-align: center;">
                <!-- <span>当前{{ item.avename[p] }}：</span>
                <span style="color: blue;">{{ item.avedata[p] }}</span> -->
                <span>当前{{ item.currentDeviceName[p] }}：</span>
                <span style="color: blue;">{{ item.currentDevice[p] }}</span>
              </div>
              <div>
                <div :id="'line' + items" style="height: 30vh;"></div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="采收信息" name="sixth" v-if="activeSate">
        <div style="width:100vw ;font-family: Regular;font-size: 14px;">
          <div style="background-color: darkmagenta;margin-left:4vw;margin-right:4vw;margin-bottom:1vh;border-radius: 8px;">
            <div class="process" style="color: #fff;">
              <span>采收阶段</span>
              <span style="float: right;margin-right: 4vw;">开始时间</span>
            </div>
          </div>

          <div v-for="item in recoveryRecordBeans" :key="item.id" style="margin-left:4vw;margin-right:4vw;background-color: mediumpurple;border-radius: 8px;margin-top:2vh">
            <div class="process" style="color: blue;">
              <span>采收批次：</span>
              <span>{{ item.recoveryBatch }}</span>
            </div>
            <div class="process">
              <span>采收地块</span>
              <span style="float: right;margin-right: 4vw;">{{ item.plotName }}</span>
            </div>
            <div class="process">
              <span>负责人</span>
              <span style="float: right;margin-right: 4vw;color: blue;">{{ item.recoveryPrincipalName }}</span>
            </div>
            <div class="process">
              <span>种植批次</span>
              <span style="float: right;margin-right: 4vw;color: blue;">{{ recoveryBatchNumber.batchNumber }}</span>
            </div>
            <div class="process">
              <span>收货量</span>
              <span style="float: right;margin-right: 4vw;color: blue;">{{ item.harvestYield }}</span>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="加工信息" name="seventh">
        <div style="width:100vw ;height: 60vh;font-family: Regular;font-size: 14px;">
          <div style="background-color: burlywood;margin-left:4vw;margin-right:4vw;margin-bottom:1vh;border-radius: 8px;">
            <div class="process" style="color: #fff;">
              <span>加工阶段</span>
              <span style="float: right;margin-right: 4vw;">开始时间</span>
            </div>
          </div>
          <div style="margin-left:4vw;margin-right:4vw;background-color: antiquewhite;border-radius: 8px;">
            <div class="process" style="color: blue;">
              <span>加工批次：</span>
              <span>SFBH001</span>
            </div>
            <div class="process">
              <span>产品名称</span>
              <span style="float: right;margin-right: 2vw;">冬小麦一号</span>
            </div>
            <div class="process">
              <span>工序名称</span>
              <span style="float: right;margin-right: 2vw;">产品包装</span>
            </div>
            <div class="process">
              <span>工序备注</span>
              <span style="float: right;margin-right: 2vw;color: blue;">对冬小麦一号进行产品包装</span>
            </div>
            <div class="process">
              <span>加工时间</span>
              <span style="float: right;margin-right: 2vw;color: blue;">2020-03-27</span>
            </div>
            <div class="process">
              <span>负责人</span>
              <span style="float: right;margin-right: 2vw;color: blue;">李菲</span>
            </div>
            <div class="processImg">
              <span>加工图片</span>
              <span style="float: right;margin-right: 2vw;">
                <img style="width:50px ;height: 50px;margin-right: 2vw;" src="../../assets/H5/956.png" alt="" />
                <img style="width:50px ;height: 50px;" src="../../assets/H5/956.png" alt="" />
              </span>
            </div>
          </div>
        </div>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-hls';
export default {
  data() {
    return {
      activeName: 'first',
      cameraBeanList: [],
      deviceBeanList: [],
      landPlotBean: {},
      plantingBatchBean: {},
      enterpriseBean: {},
      deviceList: [],
      technological: [],
      batchInfo: {},
      recoveryRecordBeans: [],
      recoveryBatchNumber: null,
      harvestBatchList: [],
      activeSate: null,
    };
  },
  // created() {
  //   this.getEcharts();
  // },
  mounted() {
    // this.getEcharts();
    console.log(this.GetQueryString('id'));
    console.log(this.GetQueryString('active'));
    if (this.GetQueryString('active')) this.activeName = this.GetQueryString('active');
    if (this.GetQueryString('active')) this.activeSate = this.GetQueryString('active');
    this.h5BaseInfo();
    this.requestDevice(this.GetQueryString('id'));
    this.plantingStage(this.GetQueryString('id'));
    this.getRecoveryInfo();
  },
  computed: {
    inputQuantity() {
      return (data) => {
        if (data.length != 0) {
          let useQuantity = 0;
          data.forEach((v) => (useQuantity += v.useQuantity));
          return useQuantity;
        }
        return 0;
      };
    },
    inputName() {
      return (data) => {
        if (data.length != 0) {
          let name = '';
          data.forEach((v) => (name += v.name));
          return name;
        }
        return '暂无';
      };
    },
    harvestBatch() {
      let data = this.recoveryRecordBeans;
      let str = '',
        start = '';
      if (data && data.length != 0) {
        data.forEach((v) => (str += v.recoveryBatch + ','));
        start = str.substring(0, str.length - 1);
        return start;
      }
      return str;
    },
    // receiptImg() {
    //   return (imgInfo) => {
    //     let ingList;
    //     if (imgInfo) {
    //       ingList = JSON.parse(imgInfo);
    //       console.log(ingList, 'opo0o0o0o0o0');
    //     }
    //     console.log(imgInfo, 'opo0o0o0o0o0');
    //     return '';
    //   };
    // },
  },
  methods: {
    /**
     * @description: 批次信息
     */
    h5BaseInfo() {
      let batchId = this.GetQueryString('id');
      if (!batchId) return this.$message.info('信息查询出错');
      this.$get('/h5/baseInfo', { batchId }).then((res) => {
        if (res.data.state == 'success') {
          console.log("基本数据：",res);
          this.cameraBeanList = res.data.data.cameraBeanList;
          this.deviceBeanList = res.data.data.deviceBeanList;
          this.landPlotBean = res.data.data.landPlotBean;
          this.plantingBatchBean = res.data.data.plantingBatchBean;
          this.enterpriseBean = res.data.data.enterpriseBean;
          this.harvestBatchList = res.data.data.recoveryRecordBeans; //采收
        }
      });
    },
    /**
     * @description: 种植阶段
     */

    plantingStage(plantingBatchId) {
      this.$get('/plantingBatch/getProductCyclestateAndFarmTask', { plantingBatchId }).then((res) => {
        if (res.data.state == 'success') {
          this.technological = res.data.data.list[0];
          this.batchInfo = res.data.data.list[1];
          this.technological.forEach((v) => {
            if (v.list && v.list.length != 0) {
              v.list.forEach((r) => {
                if (r.farmingTaskNewBean.receiptImg) {
                  r.farmingTaskNewBean.receiptImg = JSON.parse(r.farmingTaskNewBean.receiptImg);
                }
              });
            }
          });
          console.log(this.technological, '000000000');
          console.log(this.batchInfo, '000000000');
        }
      });
    },
    // sixRight(data){
    //     console.log(data,'ceshi---------------------------')
    //     return `测试`;
    // },
    /**
     * @description: 摄像头
     */
    lookCamera() {
      this.$nextTick(() => {
        this.cameraBeanList.forEach((v) => {
          this.airPlayer = videojs('screenVideo' + v.id, {
            autoplay: true,
            controls: true,
            bigPlayButton: true,
            hls: true,
          });
          this.airPlayer.src({ src: v.hls, type: 'application/x-mpegURL' });
          this.airPlayer.play();
        });
      });
    },
    /**
     * @description: 请求设备号
     */
    requestDevice(id) {
      this.$get('/plantingBatch/getDeviceFromPlantingBatchId', { id }).then((res) => {
        if (res.data.state == 'success') {
          let { deviceBeanList } = res.data.data;
          deviceBeanList.forEach((v) => this.deviceData(v.deviceNumber,v.deviceType));                                    
          console.log(this.deviceList);
        }
      });
    },
    async deviceData(deviceNumber,deviceType) {
      const { data: { data } } = await this.$get('/device/getAverageData', { deviceNumber, type: 2 });
      const {  data: { datas: realTimeList } } = await this.$get('/deviceManage/getDeviceListSceen', { deviceCode: deviceNumber,page:1,limit:10 });
      console.log(realTimeList,'realTimeList----------------------------------')
      let myData = JSON.parse(JSON.stringify(data));
      myData.currentDevice=realTimeList[0].data.split('|');
      myData.currentDeviceName=realTimeList[0].type.split('/');
      myData._ids = [];
      if (myData.currentDeviceName.length != 0) {
        myData.currentDeviceName.forEach((p, i) => myData._ids.push(deviceNumber + '' + i));
      }
      this.deviceList.push(myData);
      console.log(myData);
    },
    /**
     * @description:  地址参数
     * @param {*} name
     */
    GetQueryString(name) {
      if (!name) return null;
      // 查询参数：先通过search取值，如果取不到就通过hash来取
      var after = window.location.search;
      after = after.substr(1) || window.location.hash.split('?')[1];
      // 地址栏URL没有查询参数，返回空
      if (!after) return null;
      // 如果查询参数中没有"name"，返回空
      if (after.indexOf(name) === -1) return null;
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      // 当地址栏参数存在中文时，需要解码，不然会乱码
      var r = decodeURI(after).match(reg);
      // 如果url中"name"没有值，返回空
      if (!r) return null;
      return r[2];
    },
    handleClick(tab, event) {
      if (tab.label == '环境信息') {
        setTimeout(() => {
          //   let deviceKey = Object.keys(this.deviceList.data);
          this.deviceList.forEach((v) => {
            console.log(v);
            let type = v.type;
            if (v._ids && v._ids.length != 0) {              
              v.dataName = Object.keys(v);
              for (let i = 0; i < v.dataName.length; i++){
                for (let j = 0; j < type.length; j++){
                  if (v.dataName[i] == type[j]) {
                    v._ids.forEach((p, l) => {
                      this.getEcharts(p, v[v.dataName[l]].data, v.time);
                    });
                  }
                }
              }
              
            }
          });
        }, 1500);
      }
      console.log(tab, event);
      if (tab.label == '视频信息') {
        this.lookCamera(this.cameraBeanList[0].hls);
      }
      if (tab.label == '流程信息') {
        this.getProcessInfo();
      }
      //   if (tab.label == '采收信息') {
      //     this.getRecoveryInfo();
      //   }
    },
    getEcharts(p, data, name) {
      // 基于准备好的dom，初始化echarts实例
      console.log(data, name);
      let myChart = echarts.init(document.getElementById('line' + p));
      let label = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];
      //   let value = [50, 65, 80, 85, 100, 95, 75, 45, 40, 65, 70, 75, 85, 80, 75, 70, 75, 80, 75, 70, 65, 60, 45, 40, 45, 60, 65, 70, 75, 78, 80];
      // let label = name;
      let value = data;
      let option = {
        // backgroundColor: '#eee',
        grid: {
          top: '15%',
          left: '10%',
          right: '10%',
          // bottom: '15%',
          // top: 100,
          // containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(255,255,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255,255,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(255,255,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              formatter: '{value}',
              fontSize: 10,
              margin: 20,
              textStyle: {
                color: 'black',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#243753',
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#243753',
              },
            },
            axisTick: {
              show: false,
            },
            data: label,
          },
        ],
        yAxis: [
          {
            boundaryGap: false,
            type: 'value',
            axisLabel: {
              textStyle: {
                color: 'black',
              },
            },
            nameTextStyle: {
              color: '#fff',
              fontSize: 10,
              lineHeight: 40,
            },
            splitLine: {
              show: false,
              // lineStyle: {
              // color: '#243753',
              // },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#283352',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '大气温度',
            type: 'line',
            smooth: true,
            showSymbol: true,
            symbolSize: 2,
            zlevel: 3,
            itemStyle: {
              color: '#19a3df',
              borderColor: '#a3c8d8',
            },
            lineStyle: {
              normal: {
                width: 2,
                color: '#19a3df',
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(88,255,255,0.2)',
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(88,255,255,0)',
                    },
                  ],
                  false
                ),
              },
            },
            data: value,
          },
        ],
      };
      myChart.setOption(option);
    },
    //获取流程信息
    getProcessInfo() {
      let batchId = this.GetQueryString('id');
      this.$get('/plantingBatch/getProductCyclestate', {
        plantingBatchId: batchId,
      }).then((res) => {
        if (res.data.state == 'success') {
          let m = res.data.data;
        }
      });
    },
    //获取采收信息
    getRecoveryInfo() {
      let batchId = this.GetQueryString('id');
      this.$get('/plantingBatch/getRecoveryRecord', {
        plantingBatchId: batchId,
      }).then((res) => {
        if (res.data.state == 'success') {
          this.recoveryRecordBeans = res.data.data.recoveryRecordBeans;
          this.recoveryBatchNumber = res.data.data.plantingBatchBean;
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.heaberColor {
  width: 100vw;
  /* height: 100vh; */
  overflow: auto;
}

.heaber .el-page-header {
  font-family: Regular;
  color: #fff;
}
.heaber .el-page-header__title {
  font-size: 14px;
  font-weight: 500;
  /* font-family: Regular;
    color: #fff; */
}
.heaber .el-page-header__content {
  font-size: 18px;
  /* font-family: Regular; */
  color: #fff;
}
.headerImg {
  margin-left: 3vw;
  margin-top: 3vh;
  width: 94%;
  height: 100%;
  background-size: 100% 100%;
}
.main {
  background-color: #fff;
  box-shadow: 5px 5px 5px #dcdfe6;
  margin-left: 7vw;
  margin-top: 1vh;
  width: 85%;
  border-radius: 12px;
  height: 22vh;
  overflow: hidden;
}
.main_right {
  font-family: Regular;
  font-size: 12px;
  letter-spacing: 1px;
  color: #000;
  margin-left: 4vw;
  line-height: 4vh;
}
.basic {
  margin-left: 4vw;
  line-height: 5vh;
  border-bottom: 1px solid #dcdfe6;
  margin-right: 4vw;
}
.flow {
  margin-left: 4vw;
  line-height: 4vh;

  /* border-bottom: 1px solid #dcdfe6; */
  margin-right: 4vw;
}
.process {
  margin-left: 4vw;
  line-height: 5vh;
  /* border-bottom: 1px solid #dcdfe6; */
  margin-right: 4vw;
}
.processImg {
  margin-left: 4vw;
  line-height: 8vh;
  /* border-bottom: 1px solid #dcdfe6; */
  margin-right: 4vw;
}
.heaberColor /deep/ .el-tabs__header {
  padding: 0 1.5vh;
  box-sizing: border-box;
}
</style>
